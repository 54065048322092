<template>
  <div class="service-wrap style3 ptb-100 bg-rock">
    <div class="container">
      <img
        src="../../assets/images/service-shape-1.png"
        alt="Image"
        class="service-shape-one"
      />
      <img
        src="../../assets/images/service-shape-2.png"
        alt="Image"
        class="service-shape-two"
      />
      <div class="section-title style1 text-center mb-40">
        <h3 class="text-white">At NDDC Staff Microfinance Bank We Offer You</h3>
      </div>
      <div class="row gx-5 align-items-center">
        
        <div class="col-md-6">
          <div class="service-card style3">
            <span class="service-icon">
              <i class="flaticon-credit-card"></i>
            </span>
            <div class="service-info">
              <h3>
                <router-link to="/service-details">Thrift savings sevices</router-link>
              </h3>
              <p>
                We take pride in serving millions of federal employees who serve our country by providing them the best retirement savings and investment plans.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="service-card style3">
            <span class="service-icon">
              <i class="flaticon-loan-1"></i>
            </span>
            <div class="service-info">
              <h3>
                <router-link to="/service-details">Loan services</router-link
                >
              </h3>
              <p>
                We provide you our ordinary, special and target loan services with the best interest rates to match your plans and budget.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="service-card style3">
            <span class="service-icon">
              <i class="flaticon-payment-method"></i>
            </span>
            <div class="service-info">
              <h3>
                <router-link to="/service-details">Mobile Banking</router-link>
              </h3>
              <p>
                Our mobile banking platforms is readily available to bring our banking services within your reach, with unprecedented ease of access.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="service-card style3">
            <span class="service-icon">
              <i class="flaticon-computer"></i>
            </span>
            <div class="service-info">
              <h3>
                <router-link to="/service-details">Secured Payment</router-link>
              </h3>
              <p>
                We partner with the best online payment providers to enable our customers have a secured, fast and reliable payment experience
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-20">
        <router-link to="/services" class="btn style1"
          >View All Services</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>
