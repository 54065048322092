<template>
    <div class="about-wrap style1 " style="padding-top: 28px; padding-bottom:30px">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="about-img-wrap">
                        <img src="../../assets/images/about/about-shape-1.png" alt="Image" class="about-shape-one bounce">
                        <img src="../../assets/images/about/about-shape-2.png" alt="Image" class="about-shape-two moveHorizontal">
                        <img src="../../assets/images/about/about-img-1.png" alt="Image" class="about-img">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-content">
                        <img src="../../assets/images/about/about-shape-3.png" alt="Image" class="about-shape-three">
                        <div class="content-title style1" style="margin-top: -55px;">
                            <span style="font-size:28px">Our Vision</span>
                            <p>We have a vision to accelerate the transformation of the Niger Delta Region from poverty to sustainable
                             wealth through aggressive financial inclusion and people centric intermediation.
                             <br/>
                             <b>At the core of the values of the NDDC staff microfinance bank (NSM Bank) limited, we prioritize</b>
                             </p>
                        </div>
                        
                        <ul class="content-feature-list style1 list-style">
                            <li><span><i class="flaticon-tick"></i></span>Partnership.</li>
                            <li><span><i class="flaticon-tick"></i></span>Empathy</li>
                            <li><span><i class="flaticon-tick"></i></span>Integrity.</li>
                        </ul><b id="ourmision"/>
                        <router-link to="/about" class="btn style1">More About us</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SmartBanking'
}
</script>