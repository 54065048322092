<template>
    <div class="Login-wrap ptb-100">
        <div class="container">
            <div class="row ">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                    <div class="login-form-wrap">
                        <div class="login-header">
                            <h3>Open an account with us today</h3>
                            <p>
                            To open an account with us, please download and fill the account opening form below and forward scanned copy of completed form to us at support@nsmbanklimited.com
                            </p>
                        </div>
                        <div class="login-form">
                            <div class="login-body">
                                <form class="form-wrap" @submit.prevent>
                                    <div class="row">
                                        
                                        
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <a href="/resources/nsmbanklimited_account_openling_form.pdf" class="btn style1">
                                                    DOWNLOAD ACCOUNT OPENING FORM
                                                </a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Register'
}
</script>