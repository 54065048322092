<template>
    <div class="contact-wrap">
        <div class="comp-map" style="height: 1000px;">
                <iframe src="https://dashboard.tawk.to/login"></iframe>
            </div>
        
    </div>
</template>

<script>
export default {
    name: 'CustomerRelationship'
}
</script>