<template>
    <div class="terms-wrap ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 offset-xl-1">
                    <div class="single-terms">
                        <h3>LEGAL DISCLAIMER: </h3>
                        <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, subscribe to the newsletter, fill out a form, and in connection with other <strong>activities</strong>, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number, <router-link to="/">NDDC Staff Coop Microfinance Bank </router-link>. We will collect personal identification information from Users only if they voluntarily consent such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</p>
                    </div>
                    <div class="single-terms">
                        <h3>DESCRIPTION OF SERVICE</h3>
                        <p><b>NDDC Staff Coop Microfinance Bank’s</b> website provides you with access to resources that are rich in content concerning the Bank and its services. You understand and agree that the Service is provided “AS-IS” and that NDDC Staff Coop Microfinance Bank assumes no responsibility for the timeliness, deletion, or failure to store any user communications. You are responsible for obtaining access to the Service and that access may involve third party fees (such as Internet service provider or airtime charges etc.). You agree to be responsible for those fees.</p>
                        
                    </div>
                    <div class="single-terms">
                        <h3>REQUIREMENT FOR FILLING ONLINE FORM</h3>
                        
                        <p>In consideration of your use of the Service, you agree to provide true, accurate, current and complete information about yourself as indicated in the form’s section. If you provide any information that is untrue, inaccurate, not current or incomplete, or <b>NDDC Staff Coop Microfinance Bank </b>has a reasonable ground to suspect that such information is untrue, inaccurate, not current, or incomplete, NDDC Staff Coop Microfinance Bank has the right to delete your form data from our server.</p>
                    </div>
                    
                    <div class="single-terms">
                        <h3>WEB ACCOUNT OPENING</h3>
                        <p>Your initiation of the web account opening process does not automatically guarantee that the account(s) will be opened on your behalf. You agree that the account opening will be subject to the Bank’s internal processes and reviews which may require you to provide further confirmations or documents. You agree to comply with the standard account opening documentation requirements and to meet KYC requirements as stipulated by NDDC Staff Coop Microfinance Bank. NDDC Staff Coop Microfinance Bank reserves the right to accept or reject your application.</p>
                    </div>
                    <div class="single-terms">
                        <h3>INDEMNITY</h3>
                        
                        <p>You agree to indemnify and hold <b>NDDC Staff Coop Microfinance Bank,</b> and its affiliates, officers, agents, or other partners, and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of any material you submit, post, transmit or make available through the Service, your use of the Service, your connection to the Service, your violation of the Terms of Service, or your violation of any other rights howsoever.</p>
                    </div>
                     
                      <div class="single-terms">
                        <h3>LIMITATION OF LIABILITY</h3>
                        
                        <p>You expressly understand and agree that <b>NDDC Staff Coop Microfinance Bank </b> shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses.</p>
                    </div>

                    <div class="single-terms">
                        <h3>TRADEMARK INFORMATION</h3>
                        
                        <p>The name <b>“NDDC Staff Coop Microfinance Bank”</b> and <b>NDDC Staff Coop Microfinance Bank’s</b> logo are trademarks of NDDC Staff Coop Microfinance Bank of Nigeria Limited.</p>
                    </div>

                    <div class="single-terms">
                        <h3>COPYRIGHTS</h3>
                        
                        <p><b>NDDC Staff Coop Microfinance Bank</b> respects the intellectual property of others, and we ask our customers to do the same. If you believe that your work has been the subject of a copyright infringement, or your intellectual property rights have been otherwise violated, please provide <b> NDDC Staff Coop Microfinance Bank</b> Webmaster the following information: </p>
                       
                        <ol>
                        <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;</li>
                        <li>A description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
                        <li>A description of where the material that you claim is infringing is located on the site;</li>
                        <li>Your address, telephone number, and e-mail address;</li>
                        <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
                        <li>A statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner’s behalf.</li>
                        </ol>
                        <p><b>NDDC Staff Coop Microfinance Bank’s</b> Agent for Notice of claims of copyright or other intellectual property infringement can be reached through admin@nsmbanklimited.com.</p>
                    </div>

                    <div class="single-terms">
                        <h3>GENERAL INFORMATION</h3>
                        
                        <p>The Terms of Service constitute the entire agreement between you and <b>NDDC Staff Coop Microfinance Bank</b> and governs your use of the Service, superseding any prior agreements between you and <b> NDDC Staff Coop Microfinance Bank.</b> You also may be subject to additional terms and conditions that may apply when you use affiliate services, third-party material or third-party software. The Terms of Service and the relationship between you and <b> NDDC Staff Coop Microfinance Bank</b> shall be governed by the laws of the Federal Republic of Nigeria without regard to any conflict of law provisions. You and <b> NDDC Staff Coop Microfinance Bank </b> agree to submit to the exclusive jurisdiction of the courts located within Rivers State of Nigeria. The failure of <b>NDDC Staff Coop Microfinance Bank</b> to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision or prevent a subsequent enforcement of that or any other right or provision. If any provision of the Terms of Service is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and that all other provisions of the Terms of Service shall remain in full force and effect. You agree that regardless of any provision of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service or the Terms of Service must be filed within one (1) year after such claim or cause of action arose failing which the right to such claim or cause of action shall be automatically extinguished.

</p>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsOfService'
}
</script>