<template>
<b id="servicepanel1"/>
    <div class="service-wrap ptb-100">
        <div class="container">
        
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-payment-method"></i></span>
                                <h3><router-link to="/service-details">Savings</router-link></h3>
                            </div>
                            <p>We provide various savings plans for different societal needs including for 
                            NDDC Staff, workers of well-structured organizations and specialized savings account for children education.</p>
                            <router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-mortarboard"></i></span>
                                <h3><router-link to="/service-details">Loans</router-link></h3>
                            </div>
                            <p>We provide loans for business enterprises, start-ups and sole proprietors, members of Cooperative Societies, women groups and Farmers Associations, traders and artisans...</p>
                            <router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-loan-1"></i></span>
                                <h3><router-link to="/service-details">Salary advance</router-link></h3>
                            </div>
                            <b id="servicepanel1"/>
                            <p>We provide advance salary payment plans to NDDC Staff and workers of well-structured organizations to leverage on our goal to increase societal access to funds and meet pressing needs.</p>
                            <router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        <b id="servicepanel2"/>
                        </div>
                    </div>
                </div>
                
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-secure-shield"></i></span>
                                <h3><router-link to="/service-details">Overdraft facility</router-link></h3>
                            </div>
                            <p>With out Overdraft financial plan, financial facility or instrument that enables you to withdraw money from your bank account (savings or current), even if you do not have any account balance..</p>
                            <router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="page-nav list-style mt-20">
                <li><a href="/services-two"><i class="flaticon-back"></i></a></li>
                <li><a class="active" href="/services-two">1</a></li>
                <li><a href="/services-two">2</a></li>
                <li><a href="/services-two">3</a></li>
                <li><a href="/services-two"><i class="flaticon-next-1"></i></a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>