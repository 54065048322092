<template>
    <div class="feature-wrap pt-100 pb-75">
        <div class="container">
        <div class="row" style="margin-top: -68px;margin-bottom: -15px;">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="section-title style1 text-center mb-40">
                        <span>Our loan request roadmap</span>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img src="../../assets/images/feature/feature-icon-1.png" alt="Image"></span>
                                <h3>Create an account</h3>
                            </div>
                            <p>Open a new customer account to start accessing our NSM Banking services once account creation has been completed</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img src="../../assets/images/feature/feature-icon-2.png" alt="Image"></span>
                                <h3>Apply for loan</h3>
                            </div>
                            <p>Apply for any of our Ordinary, target or Special loans using our offline or online loan request forms and submit for review.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="feature-card style3">
                        <div class="feature-info">
                            <div class="feature-title">
                                <span><img src="../../assets/images/feature/feature-icon-3.png" alt="Image"></span>
                                <h3>Receive Money</h3>
                            </div>
                            <p>Funds will be released once your loan request form is received, and required verifications and validations is successful.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>