<template>
    <div class="terms-wrap ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 offset-xl-1">
                    <div class="single-terms">
                        <h3>History of the Bank </h3>
                        <p>
                            The NDDC Staff Micro Finance Bank Limited was promoted by the NDDC Port Harcourt Staff Co-operative Investment and Credit Society Limited. The idea behind formation of the Micro Finance Bank was born out of the determination of the Staff of the NDDC to help drive the economic prosperity of the oil rich Niger Delta Region which is a key component of the mandate of the NDDC. 
                            <br/>
                            <br/>
                            The establishment of the NDDC Staff Micro Finance Bank Limited started in 2019 by the 3rd Management Committee of the Cooperative led by Mr. Essien Franklin. After the expiration of their tenor in office, the 5th Management Committee led by EmerhanaOnoriode Paul, Esq, ACIArb. The Bank has its registered office at 129 Aba Road, Port Harcourt.
                        </p>
                    </div>
                </div>
            </div>  
            <div class="row  align-items-center">
                <div class="col-lg-6">
                    <div class="about-img-wrap">
                        <img src="../../assets/images/about/about-shape-1.png" alt="Image" class="about-shape-one bounce">
                        <img src="../../assets/images/about/about-shape-2.png" alt="Image" class="about-shape-two moveHorizontal">
                        <img src="../../assets/images/about/about-img-1.png" alt="Image" class="about-img">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-content">
                        <img src="../../assets/images/about/about-shape-3.png" alt="Image" class="about-shape-three">
                        <div class="content-title style1">
                            <span>Smart Banking</span>
                            <h2>The Better Way To Save &amp; Invest Online Banking</h2>
                            <p>There are many variations of passages of Lorem Ipsum amet avoilble but majority have suffered alteration in some form, by injected humur or randomise words which don't sure amet sit dolor quras alto lorem.</p>
                        </div>
                        <ul class="content-feature-list style1 list-style">
                            <li><span><i class="flaticon-tick"></i></span>Cards that work all across the world.</li>
                            <li><span><i class="flaticon-tick"></i></span>Highest Returns on your investments.</li>
                            <li><span><i class="flaticon-tick"></i></span>No ATM fees. No minimum balance. No overdrafts.</li>
                        </ul>
                        <router-link to="/about" class="btn style1">More About us</router-link>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-10 offset-xl-1">      
                    <div class="single-terms">
                        <h3>Credit Reporting Terms of Service</h3>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum, quod. Ratione ex delectus quis tenetur odio non alias numquam official ipsum dolor sit, amet consectetur adipisicing elit. Accusamus, laborum.</p>
                        <ol>
                            <li>Mauris ut in vestibulum hasellus ultrices fusce nibh justo, venenatis, amet. Lectus quam in lobortis.</li>
                            <li>Consectetur phasellus <strong>ultrices</strong> fusce nibh justo, venenatis, amet. Lectus quam in lobortis justo venenatis amet.</li>
                            <li>Lectus quam there are two thing is very important in Consectetur phasellus ultrices fusce nibh justo, venenatis, amet in lobortis.</li>
                            <li>Web Development very creative to do something , mauris ut in vestibulum. Consectetur phasellus ultrices fusce nibh justo, venenatis, amet to all design.</li>
                        </ol>
                    </div>
                    <div class="single-terms">
                        <h3>Ownership of Site Agreement to Terms of Use</h3>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla minus quasi. Voluptatem, facilis saepe ullam autem magni quod sint tempore, eius molestias aliquam debitis. Neque saepe dignissimos repudiandae fuga.</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil eveniet quas dignissimos doloribus ea pariatur corrupti rerum deserunt, ipsum, ipsa eos veniam aspernatur fuga, optio soluta? Libero neque reiciendis cupiditate dolores nam. Earum eius similique sapiente. Iure, sit non. At fuga ipsam veniam.</p>
                    </div>
                    <div class="single-terms">
                        <h3>Provision of Services</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil eveniet quas dignissimos doloribus ea pariatur corrupti rerum deserunt, ipsum, ipsa eos veniam aspernatur fuga, optio soluta? Libero neque reiciendis cupiditate dolores nam. Earum eius similique sapiente. Iure, sit non. At fuga ipsam veniam.</p>
                        <ul class="content-feature-list  list-style mb-0">
                            <li><i class="ri-checkbox-multiple-line"></i>Lorem ipsum dolor, sit amet.</li>
                            <li><i class="ri-checkbox-multiple-line"></i>Amet consectetur adipisicing elit.
                                Officia, odit!</li>
                            <li><i class="ri-checkbox-multiple-line"></i>Aquaerat ipsa quis possimus.</li>
                            <li><i class="ri-checkbox-multiple-line"></i>Lorem, ipsum dolor sit amet
                                consectetur adipi.</li>
                            <li><i class="ri-checkbox-multiple-line"></i>Consectetur adipisicing elit.
                                Voluptatibus, dignissimos.</li>
                            <li><i class="ri-checkbox-multiple-line"></i>Highly professional administration.
                            </li>
                        </ul>
                    </div>
                    <div class="single-terms">
                        <h3> Limitation of Liability</h3>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla minus quasi. Voluptatem, facilis saepe ullam autem magni quod sint tempore, eius molestias aliquam debitis. Neque saepe dignissimos repudiandae fuga.</p>
                    </div>
                    <div class="single-terms">
                        <h3> Accounts, Passwords and Security</h3>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla minus quasi. Voluptatem, facilis saepe ullam autem magni quod sint tempore, eius molestias aliquam debitis. Neque saepe dignissimos repudiandae fuga.</p>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil eveniet quas dignissimos doloribus ea pariatur corrupti ullam autem magni quod sint tempore saepe ullam autem magni.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsOfService'
}
</script>