<template>
    <div class="page-wrapper">
        <Navbar class="style1" />
        <div class="content-wrapper">
            <PageTitle pageTitle="Staff mailing" />
            <Mailing />
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Mailing from '../Mailing/Mailing'
import Footer from '../Layouts/Footer'

export default {
    name: 'StaffMail',
    components: {
        Navbar,
        PageTitle,
        Mailing,
        Footer,
    }
}
</script>