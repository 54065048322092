<template>
  <footer class="footer-wrap bg-rock">
    <div class="container">
      <div class="row pt-100 pb-75">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
          <div class="footer-widget">
            <router-link to="/" class="footer-logo">
              <h2 style="color:#fff">NSM Bank Limited</h2>
            </router-link>
            <p class="comp-desc">
              At the NDDC staff microfinance bank limited, we focus on taking away the financial hassles as much as possible; We ensure secured and easy financial footsteps and footprints for our clients.
              We partner with the best online payment providers to enable our customers have a secured, fast and reliable payment experience
            </p>
            <div class="social-link">
              <ul class="social-profile list-style style1">
                <li>
                  <a target="_blank" href="https://facebook.com">
                    <i class="ri-facebook-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com">
                    <i class="ri-twitter-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://linkedin.com">
                    <i class="ri-linkedin-fill"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://instagram.com">
                    <i class="ri-pinterest-fill"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Site Map</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/about">
                  <i class="flaticon-next"></i>
                  Home
                </router-link>
              </li>
              <li>
                <router-link to="/services">
                  <i class="flaticon-next"></i>
                  About us
                </router-link>
              </li>
              <li>
                <router-link to="/team">
                  <i class="flaticon-next"></i>
                  Our Mission
                </router-link>
              </li>
              <li>
                <router-link to="/pricing">
                  <i class="flaticon-next"></i>
                  Our Vision
                </router-link>
              </li>
              <li>
                <router-link to="/contact">
                  <i class="flaticon-next"></i>
                  Board members
                </router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">
                  <i class="flaticon-next"></i>
                  Management
                </router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">
                  <i class="flaticon-next"></i>
                  Contact Us
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Products</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/loans">
                  <i class="flaticon-next"></i>
                  Loans
                </router-link>
              </li>
              <li>
                <router-link to="/savingsaccount">
                  <i class="flaticon-next"></i>
                  Savings accounts
                </router-link>
              </li>
              <li>
                <router-link to="/currentaccount">
                  <i class="flaticon-next"></i>
                  Current accounts
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Legal</h3>
            <ul class="footer-menu list-style">
              <li>
                <router-link to="/privacy-policy">
                  <i class="flaticon-next"></i>
                  Privacy policy
                </router-link>
              </li>
              <li>
                <router-link to="/terms-of-service">
                  <i class="flaticon-next"></i>
                  Terms of use
                </router-link>
              </li>
              <li>
                <router-link to="/loan-policy">
                  <i class="flaticon-next"></i>
                  Loan policy
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
          <div class="footer-widget">
            <h3 class="footer-widget-title">Subscribe</h3>
            <p class="newsletter-text">
              To receive NSM Bank newsletter updates please enter email and subscribe below
            </p>
            <form @submit.prevent class="newsletter-form">
              <input type="email" placeholder="Your Email" />
              <button type="button">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-text">
      <p>
        <i class="ri-copyright-line"></i>{{ currentYear }} NDDC Staff Microfinance Bank. 
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
