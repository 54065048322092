<template>
    <div class="terms-wrap pt-100 pb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-lg-12">
                    <div class="single-terms">
                        
                       
                    </div>
                    <div class="single-terms">
                        <h3>YOUR LOAN</h3>
                        <p>
                            <b>NDDC Staff Coop Microfinance Bank (NDDC Microfinance Bank or Lender)</b> is granting Loans to interested and eligible customers of NDDC Microfinance Bank (hereinafter referred to as “Borrower”) via its Mobilr App or all other digital channels made available for this loan service under the following terms & conditions:
                             <ol>
                                <li>Customer writes and submits loan application to the bank duly signed by customer</li>
                                <li>The applicants request is appraised in line with laid down criteria</li>
                                <li>Application is verified against mandate</li>
                                <li>Amount applied for falls within obligor’s limit</li>
                                <li>Guarantors provided meet acceptable criteria</li>
                                <li>Credit search is conducted and successful</li>
                                <li>In some cases, police report is obtained and ‘CLEAN’</li>
                                <li>Deposit is made cover up front fees/charges </li>
                                
                               
                            </ol>
                        </p>
                       
                    </div>
                    <div class="single-terms">
                        <h3>ELIGIBILTY CRITERIA/ REQUIREMENTS OF BORROWERS</h3>
                        <ul>
                            <li>Be Nigerian by birth </li>
                            <li>Have a known business within the catchment area of NDDC Microfinance Bank</li>
                            <li>In the case of salary earners must be confirmed employees in the public sector</li>
                            <li>Have been in business, trade vocation for a period not less than one year prior to opening account with Bank</li>
                            <li>The loans must be for business purposes, asset procurement, agriculture, education purposes, loans for salary earners, equipment purchase for particular contracts</li>

                        </ul>
                        
                       
                    </div>
                    <div class="single-terms">
                        <h3>PRODUCTS OFFERED BY NDDC STAFF COOP MICROFIANCE BANK LIMITED</h3>
                        <p>
                            <b>NDDC Staff Coop Microfinance Bank Limited</b> offers the following banking products & services:
                             <ol>
                                <li>NDDC Staff Coop Microfinance Bank Limited Trader Loan</li>
                                <li>NDDC Staff Coop Microfinance Bank Market Women Loan Scheme</li>
                                <li>NDDC Staff Coop Microfinance Bank Limited Children Education Account</li>
                                <li>NDDC Staff Coop Microfinance Bank Limited MSME Loans</li>
                                <li>NDDC Staff Coop Microfinance Bank Limited Salary Advance</li>
                                <li>NDDC Staff Coop Microfinance Bank Limited Overdraft Facilities</li>
                                <li>NDDC Staff Coop Microfinance Bank Limited Agro-business Loans</li>
                                <li>NDDC Staff Coop Microfinance Bank Limited Green Loans.</li>
                                <li>NDDC Staff Coop Microfinance Bank Limited Artisan Loan Scheme</li>
                                <li>NDDC Staff Coop Microfinance Bank Limited Asset Finance Facility</li>
                                
                               
                            </ol>
                        </p>
                       
                    </div>
                
                    <div class="single-terms">
                        <h3>LOAN PORTFOLIO COMPOSITION.</h3>
                        <p>The value of the Loan portfolio of NDDC Microfinance Bank shall at all times be a minimum of 80% for micro loans and a maximum of 20% for small and medium enterprises(SMEs)</p>
                    </div>

                    
                    <div class="single-terms">
                        <h3>CONSENT</h3>
                        <p>We don't ask for your personal information unless we need it to provide or improve our products and services for you. We want to be sure we have your consent to collect, use and, where necessary, share your information with our partners and suppliers that help us serve you. Whenever we introduce new services and technologies, we'll ensure you understand and agree to any new ways in which your information is handled. You will be considered to have given your consent to NDDC Staff Coop Microfinance Bank and/or Subsidiaries for the processing of your personal data when:
You complete any form, brochure or material issued by NDDC Staff Coop Microfinance Bank at any of our service points (mobile, online etc.) requesting for such personal information.
You register, check or tick the acceptance box on any of our electronic platforms (Online or Mobile) relating to terms and conditions of any service or product offered by NDDC Staff Coop Microfinance Bank.
You send a request, complaint or other communication to NDDC Staff Coop Microfinance Bank 
You use any service or product offered by NDDC Staff Coop Microfinance Bank.</p>
                       
                    </div>
                    <div class="single-terms">
                        <h3>USE OF PERSONAL INFORMATION</h3>
                         <ol>
                            <li>To offer and provide our Products and Services tailored to meet your unique needs.</li>
                            <li>To fulfil the terms of any service contract(s) you might have with us.</li>
                            <li>To improve your service experience with us.</li>
                            <li>To conduct our business.</li>
                            <li>To develop statistics as may be required</li>
                            <li>To update your records.</li>
                            <li>To communicate with you when necessary.</li>
                            <li>To comply with our Internal Policies.</li>
                        </ol>
                    </div>

                    <div class="single-terms">
                        <h3>AVAILABILITY:</h3>
                         <p>This facility shall be available for utilization only upon satisfactory compliance with the conditions precedent to drawdown as may be prescribed <b>NDDC Staff Coop Microfinance Bank.</b></p>
                    </div>

                    <div class="single-terms">
                        <h3>LEGAL AND OTHER EXPENSES:</h3>
                        <p>The Borrower shall reimburse NDDC Microfinance Bank for all reasonable out of pocket expenses, costs, and charges incurred by NDDC Microfinance Bank in connection with the preparation, execution, administration and enforcement of the terms of this offer, including but not limited to Solicitors fees, Stamp Duties and Registration fees. Such charges and costs shall in the event of non-payment by the Borrower on demand be added to the facility and other moneys owing under the terms of this offer and shall bear interest accordingly.</p>
                        
                    
                    </div>

                     <div class="single-terms">
                        <h3>REASONS THE BOARD MAY DECLINE APPROVAL</h3>
                        <ol>
                            <li>Overall risk: concentration risk</li>
                            <li>Overall Cooperate objectives</li>
                            <li>Excessive pressure on liquidity</li>

                        </ol>
                       
                    </div>

                     <div class="single-terms">
                        <h3>REPAYMENT</h3>
                        <p>The Borrower shall repay the facility within its term.
                            Where the Borrower account is not funded to repay the loan, Borrower is notified, and the system will keep trying for the next 24hours after which other customer(Borrower) accounts is debited for the loan.
                            No new facility will be granted until account is regularized.</p>
                       
                        
                    </div>

                   
                    

                <div class="single-terms">
                        <h3>UPDATES ON LOAN POLICY</h3>
                        <p><b>NDDC Staff Coop Microfinance Bank</b> may update this policy from time to time. Where there are changes in the way we use your personal information we will notify you by posting a prominent notice on our website.</p>
                        
                        
                    </div>

                </div>

              
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoanPolicy'
}
</script>