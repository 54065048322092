<style>
/* Outer */
.popup {
    width:100%;
    height:100%;
    display:none;
    position:fixed;
    top:0px;
    left:0px;
    background:rgba(0,0,0,0.75);
}
 
/* Inner */
.popup-inner {
    max-width:700px;
    width:90%;
    padding:40px;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform:translate(-50%, -50%);
    transform:translate(-50%, -50%);
    box-shadow:0px 2px 6px rgba(0,0,0,1);
    border-radius:3px;
    background:#fff;
}
 
/* Close Button */
.popup-close {
    width:30px;
    height:30px;
    padding-top:4px;
    display:inline-block;
    position:absolute;
    top:0px;
    right:0px;
    transition:ease 0.25s all;
    -webkit-transform:translate(50%, -50%);
    transform:translate(50%, -50%);
    border-radius:1000px;
    background:rgba(0,0,0,0.8);
    font-family:Arial, Sans-Serif;
    font-size:20px;
    text-align:center;
    line-height:100%;
    color:#fff;
}
 
.popup-close:hover {
    -webkit-transform:translate(50%, -50%) rotate(180deg);
    transform:translate(50%, -50%) rotate(180deg);
    background:rgba(0,0,0,1);
    text-decoration:none;
}



.popup-scroll{
  overflow-y: scroll;
  max-height: 300px;
  padding:0 1em 0 0;
}
.popup-scroll::-webkit-scrollbar {background-color:#EEE;width:10px;}
.popup-scroll::-webkit-scrollbar-thumb {
	border:1px #EEE solid;border-radius:2px;background:#777;
	-webkit-box-shadow: 0 0 8px #555 inset;box-shadow: 0 0 8px #555 inset;
	-webkit-transition: all .3s ease-out;transition: all .3s ease-out;
	}
.popup-scroll::-webkit-scrollbar-track {-webkit-box-shadow: 0 0 2px #ccc;box-shadow: 0 0 2px #ccc;}	
</style>
<template>
    <div class="team-wrap pt-100 pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                        <div class="team-img">
                            <a  data-popup-open="popup-1" href="#">
                            
                            </a>
                            <ul class="social-profile list-style style1">
                                <li>
                                    <a target="_blank" href="https://facebook.com">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://linkedin.com">
                                        <i class="ri-linkedin-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://pinterest.com">
                                        <i class="ri-pinterest-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-info">
                            <a  data-popup-open="popup-1" href="#">
                            </a>
                        </div>
 
                    </div>
                    
                    <div class="popup" style="z-index: 1000;" data-popup="popup-1">
                        <div class="popup-inner">
                        <center>
                            <div class="team-info">
                            </div>
                        </center>
                        <hr/>
                            <div class="popup-scroll">

                            </div>
                            <a class="popup-close" data-popup-close="popup-1" href="#">x</a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                        <div class="team-img">
                            <a  data-popup-open="popup-2" href="#">
                                <img style="width:354px;height:354px" src="../../assets/images/team/t1.jpg" alt="Image">
                            </a>
                            <ul class="social-profile list-style style1">
                                <li>
                                    <a target="_blank" href="https://facebook.com">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://linkedin.com">
                                        <i class="ri-linkedin-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://pinterest.com">
                                        <i class="ri-pinterest-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-info">
                            <a  data-popup-open="popup-2" href="#">
                                <h3>Dame Onome Adewuyi</h3>
                                <span>Board Chairman/Non-Executive Director</span>
                            </a>
                        </div>
                    </div>
                        <div class="popup" style="z-index: 1000;" data-popup="popup-2">
                            <div class="popup-inner">
                            <center>
                                <img style="width:200px;height:200px;border-radius:100px" src="../../assets/images/team/t1.jpg" alt="Image"/>
                                <div class="team-info">
                                    <h3>Dame Onome Adewuyi </h3>
                                    <span>Board Chairman/Non-Executive Director</span>
                                </div>
                            </center>
                            <hr/>
                                <div class="popup-scroll">
                                    <p>
                                        Mrs Onome Joy Adewuyi BSc, MSc, FCIB, FCA was the 56th President of the Institute of ICAN.
                                                Prior to her emergence as President, Onome Joy Adewuyi was the 2nd Deputy Vice President of ICAN. She has been a member of the Governing Council of the Institute of Chartered Accountants of Nigeria (ICAN) since 2007 and sits on the Council. She is also currently the Chair of the Membership Affairs, Staff and Organizing, and Investigation Panel Committees. Previous service with ICAN includes three terms as ICAN’s Treasurer, chairing the ICAN Professional Examinations in 2017 and chairing the IT and Image and Publicity Committees. 
                                                She began her career with Texaco Nigeria Plc. as the Treasury and Investment Accountant. She later joined the Nigerian Intercontinental Merchant Bank Limited where she started her banking career. She worked at Fidelity Bank Plc from 1997 to 2015, where she rose to the position of Executive Director Risk Management from 2009-2015. After retiring from that position, she co-founded a new business, Cynergy Platforms Limited in 2015. She is currently the Executive Director Finance and Administration.
                                                Mrs. Adewuyi also sits on the Board of Law Union and Rock Insurance Plc, Dominion Trust Co. Limited and Dominion Finance Limited as non-executive directors. 
                                                Mrs. Adewuyi holds a B.Sc Second Class Honours Degree in Accounting from the University of Benin and an M.Sc Degree in Banking and Finance from the University of Lagos. She is an alumnus of the Havard Business School, Boston Massachusetts USA; the Wharton Business School of Pennsylvania, USA and the Kellogg Business School, Chicago USA. She is also an alumnus of the International Banking Summer School, USA and the Lagos Business School.She qualified as a Chartered Accountant (ACA) in 1986, Conferred FCA of ICAN in 1997. She qualified as a Chartered Banker (ACIB) 1993 and conferred a fellow of the Chartered Institute of Bankers in 2007. She is also a fellow of the Risk Managers Association of Nigeria and was the Vice President of the Association in 2007. She is the Technical Advisor to General (rtd) Sebastian Owuama, a Board member of the International Federation of Accountants (IFAC).
                                                <center><a  data-popup-close="popup-2" href="#"><b>CLOSE</b></a></center>
                                    </p>
                                </div>
                                <a class="popup-close" data-popup-close="popup-2" href="#">x</a>
                            </div>
                        </div>
                        
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                        <div class="team-img">
                            <a  data-popup-open="popup-3" href="#">
                                <img style="width:354px;height:354px" src="../../assets/images/team/t2.jpg" alt="Image">
                            </a>
                            <ul class="social-profile list-style style1">
                                <li>
                                    <a target="_blank" href="https://facebook.com">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://linkedin.com">
                                        <i class="ri-linkedin-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://pinterest.com">
                                        <i class="ri-pinterest-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-info">
                            <a  data-popup-open="popup-3" href="#">
                                <h3>Mr. Reginald Ihebuzor </h3>
                                <span>Director (Non-executive)</span>
                            </a>
                        </div>
                    </div>
                        <div class="popup" style="z-index: 1000;" data-popup="popup-3">
                            <div class="popup-inner">
                            <center>
                                <img style="width:200px;height:200px;border-radius:100px" src="../../assets/images/team/t2.jpg" alt="Image"/>
                                <div class="team-info">
                                    <h3>Mr. Reginald Ihebuzor </h3>
                                    <span>Director (Non-Executive)
                                    </span>
                                </div>
                            </center>
                            <hr/>
                                <div class="popup-scroll">
                                    <p>
                                        Ihebuzor has over 26 years of wide-ranging management-level experience that spans the development banking and public reform sectors, Government –Government-Partnership (GGPs), Public-Private-Partnership (PPP), Institutional Reforms, Corporate Governance and Management. Previously in his career, he worked for five (5) years as the World Bank Independent Transactions Advisor at the Bureau of Public Enterprises (BPE) and had other stints at United Bank for Africa as Deputy General Manager, Pan-African Infrastructure Development Fund (on secondment) and in the Imo State Government as Commissioner for Budget and Economic Planning.
                                        He is also the Project Director of the Niger Delta Development Bank project (ongoing) for the Niger Delta Development Commission (NDDC). Mr. Ihebuzor completed a Robert S. McNamara Research Fellowship at the World Bank Institute. He earned a BSc, MSc and MBA from the University of Nigeria, University of Lagos and Enugu State University of Science & Technology respectively. He also attended the programme on Regulatory Reforms and Corporate Governance at the prestigious Harvard University, Boston, Massachusetts, USA. These skills, expertise and experience have been deployed in the successful handling of international and national engagements (in banking, private and public sector domains) at CEO, Director and Senior Management levels.
      
                                        <center><a  data-popup-close="popup-3" href="#"><b>CLOSE</b></a></center>
                                    </p>
                                </div>
                                <a class="popup-close" data-popup-close="popup-3" href="#">x</a>
                            </div>
                        </div>
                        
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                            <div class="team-img">
                                <a  data-popup-open="popup-6" href="#">
                                <img style="width:354px;height:354px" src="../../assets/images/team/t4.jpg" alt="Image">
                                </a>
                                <ul class="social-profile list-style style1">
                                    <li>
                                        <a target="_blank" href="https://facebook.com">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://linkedin.com">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://twitter.com">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://pinterest.com">
                                            <i class="ri-pinterest-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="team-info">
                                <a  data-popup-open="popup-6" href="#">
                                    <h3>Mrs. Samantha Wilson </h3>
                                    <span>Designation</span>
                                </a>
                            </div>
                    </div>
                        <div class="popup" style="z-index: 1000;" data-popup="popup-6">
                            <div class="popup-inner">
                            <center>
                                <img style="width:200px;height:200px;border-radius:100px" src="../../assets/images/team/t4.jpg" alt="Image"/>
                                <div class="team-info">
                                    <h3>Mrs. Samantha Wilson </h3>
                                    <span>(Acting) Managing Director/CEO</span>
                                </div>
                            </center>
                            <hr/>
                                <div class="popup-scroll">
                                    <p>
                                            S.I Wilson is a professional Banker, Freight Forwarder, Insurer, and Environmental Enthusiast. She graduated from the University of Port Harcourt with a 2nd Class Upper degree in Economics Education and a Masters degree in Educational Curriculum and Instruction. She is in addition a certified Microfinance Banker from the Chattered Institute of Bankers Nigeria. 

                                                S.I Wilson has a combined experience in the finance sector spanning over a decade. Starting her banking career in United Bank for Africa PLC as Executive Trainee, she is experienced in commercial banking, retail banking, electronic banking, public sector banking, international trade, loan recovery, relationship management, branch management, and cost management. She was awarded Most Valuable Performer (M.V.P) for years 2009 and 2010 consecutively. 

                                                In the Insurance sub sector, she acquired valuable skills and experience with American International Insurance Plc in general and life insurance providing cover for inventory, facility, valuable assets, life (endowment, pure risk, and annuity policies) and their associated claims.
                                                In small and medium enterprise, she has relevant sales and freight forwarding experience having worked as Business Development Executive with Gromzia Customs Consultants. The knowledge gained in international trade in banking was used to deliver exceptional services in line with organizational goals. As commercial officer in Starz Shipyard, she interacted with Tier 1 shipping companies and managed professional relationships that positively impacted the income of the organization. 

                                                S.I. Wilson is currently Public Affairs Analyst and member Board of Trustees Youth and Environmental Advocacy Centre - Nigeria. She is at the fore promoting climate change awareness, environmental justice, gender issues, human rights advocacy e.t.c. All through her career, she has undergone professional trainings in sales, entrepreneurship, international trade, service excellence, emotional intelligence, leadership, capacity building, peace building, and diplomatic relations.

                                                She is a member of Treasure Base All Stars Club where she enjoys to run to keep fit in mind and body. Her hobbies include writing poetry, mentoring/tutoring, and public speaking. She is a mother of two daughters with her permanent residence in Abuja F.C.T. 
                                                <center><a  data-popup-close="popup-6" href="#"><b>CLOSE</b></a></center>
                                
                                    </p>
                                </div>
                                <a class="popup-close" data-popup-close="popup-6" href="#">x</a>
                            </div>
                        </div>
                        
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                        
                            <div class="team-img">
                                <a  data-popup-open="popup-7" href="#">
                                    <img style="width:354px;height:354px" src="../../assets/images/team/tg.jpg" alt="Image">
                                </a>
                                <ul class="social-profile list-style style1">
                                    <li>
                                        <a target="_blank" href="https://facebook.com">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://linkedin.com">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://twitter.com">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://pinterest.com">
                                            <i class="ri-pinterest-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="team-info">
                                <a  data-popup-open="popup-7" href="#">
                                    <h3>Dr. Princewill Ekanem</h3>
                                    <span>-</span>
                                </a>
                            </div>
                        
                    </div>
                    
                        <div class="popup" style="z-index: 1000;" data-popup="popup-7">
                            <div class="popup-inner">
                            <center>
                                <img style="width:200px;height:200px;border-radius:100px" src="../../assets/images/team/tg.jpg" alt="Image"/>
                                <div class="team-info">
                                    <h3>Dr. Princewill Ekanem</h3>
                                    <span>-</span>
                                </div>
                            </center>
                            <hr/>
                                <div class="popup-scroll">
                                    <p>
                                         <center><a  data-popup-close="popup-7" href="#"><b>CLOSE</b></a></center>
                                       
                                    </p>
                                </div>
                                <a class="popup-close" data-popup-close="popup-7" href="#">x</a>
                            </div>
                        </div>
                </div>
                
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                            <div class="team-img">
                                <a  data-popup-open="popup-4" href="#">
                                    <img style="width:354px;height:354px" src="../../assets/images/team/tg.jpg" alt="Image">
                                </a>
                                <ul class="social-profile list-style style1">
                                    <li>
                                        <a target="_blank" href="https://facebook.com">
                                            <i class="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://linkedin.com">
                                            <i class="ri-linkedin-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://twitter.com">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://pinterest.com">
                                            <i class="ri-pinterest-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="team-info">
                                <a  data-popup-open="popup-4" href="#">
                                    <h3>Mr. Oma Jimoh Egbejule</h3>
                                    <span>Designation</span>
                                </a>
                            </div>  
                    </div>
                    <div class="popup" style="z-index: 1000;" data-popup="popup-4">
                            <div class="popup-inner">
                            <center>
                                <img style="width:200px;height:200px;border-radius:100px" src="../../assets/images/team/tg.jpg" alt="Image"/>
                                <div class="team-info">
                                    <h3>Mr. Oma Jimoh Egbejule</h3>
                                    <span>-</span>
                                </div>
                            </center>
                            <hr/>
                                <div class="popup-scroll">
                                    <p>
                                        Mr. JimohOmaEgbejule, is a 1982 graduate of Auchi Polytechnic and a Chartered Accountant of 32years standing rising to be a Fellow (FCA) of the Institute of Chartered Accountants of Nigeria (ICAN) in 2005. He retired from the service of the Niger Delta Development Commission (NDDC) in October, 2017 having attained the mandatory age of 60 years after spending 8 years plus as the Ag. Director to Director, Finance & Supply. 

                                                        Mr. Jimoh Egbejule attended the defunct Okere Union School, Okere and St Paul RCM School 2, Iyara, Warri for his primary education from 1963 to 1968. and later proceeded to the prestigious Hussey College, Warri for his secondary education from 1970 to 1974. 

                                                        In his working career he worked in both private and public sector establishments. After completing his National Service under the National Youth Service Corps Scheme at the Investment, Industrial and Commercial Corporation, IICC, of Oyo State in Ibadan in 1983 he worked as an Audit Semi-Senior, V. O. Odisal& Chartered Accountants in Lagos and later worked at the Ibru Organization headquarters in Lagos and was transferred Superbru Limited as subsidiary of the ABIRI group in Agbarha-Otor near Ughelli from 1985 to 1989. In 1989 he joined the Audit Department of the University of Benin as an Accountant 2. In late 1989 he joins Life Flour Mill Limited, Sapele where he rose from the position of Project Accountant to Assistant Chief Accountant before he left in 1993 join the defunct Oil Minerals Development Commission (OMPADEC). He was absorbed into the Niger Delta Development Commission in 2001 after the commission was established to replace the defunct OMPADEC.

                                                        Mr. JimohEgbejule attended several courses both locally and internationally in reputable educational and career building institutions such as Lagos Business School (LBS), Administrative Staff College of Nigeria (ASCON), Stanford Graduate School, USA and Harvard Business School, USA. 

                                                        He is Christian and joined the Church of God Mission International (CGMI) 1989 and was at one time the Secretary of the Men’s Fellowship in CGMI Abundant Life Centre, Sapele. He is currently the President of the Men’s Fellowship District Headquarters of the Church of God Mission International in his village, Ugbuwangue in Warri South Local Government Area of Delta State. 

                                                        Jimoh Egbejule is married to Deaconess Josephine his beloved wife for 44 years and the union is blessed with 4 children, Emmanuel, Blessing, Faith and Gift. 
                                                <center><a  data-popup-close="popup-4" href="#"><b>CLOSE</b></a></center>
                                    
                                    </p>
                                </div>
                                <a class="popup-close" data-popup-close="popup-4" href="#">x</a>
                            </div>
                        </div>
                        
                        
                        
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="team-card style1">
                        <div class="team-img">
                            <a  data-popup-open="popup-5" href="#">
                                <img style="width:354px;height:354px" src="../../assets/images/team/t3.jpg" alt="Image">
                            </a>
                            <ul class="social-profile list-style style1">
                                <li>
                                    <a target="_blank" href="https://facebook.com">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://linkedin.com">
                                        <i class="ri-linkedin-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://pinterest.com">
                                        <i class="ri-pinterest-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="team-info">
                            <a  data-popup-open="popup-5" href="#">
                                <h3>Mr. Nkpogone Dumnaatah Lucky</h3>
                                <span>Director (Independent)</span>
                            </a>
                        </div>
                    </div>
                    <div class="popup" style="z-index: 1000;" data-popup="popup-5">
                            <div class="popup-inner">
                            <center>
                                <img style="width:200px;height:200px;border-radius:100px" src="../../assets/images/team/t3.jpg" alt="Image"/>
                                <div class="team-info">
                                    <h3>Mr. Nkpogone Dumnaatah Lucky</h3>
                                    <span>Director (Independent)</span>
                                </div>
                            </center>
                            <hr/>
                                <div class="popup-scroll">
                                    <p>
                                            Comr. Sir Dumnaatah Lucky NKpogone is a professional teacher and an administrator. Born on 13, March 1962 at Koroma Tai his home town in Rivers State of Nigeria is happily Married with children.
                                            He attended State School, Koroma Tai 1970-1975; Government Secondary School, Ogu 1975 - 1980, Rivers State College of Education 1982-1985 and University of Port Harcourt 1987 - 1990 and graduated in 1991, with a B.A(Ed) in History.

                                            On 1 September 1986, he gained employment under the Rivers State Schools Board as a teacher and was posted to Community Comprehensive Secondary School, Nonwa. He served in Tua-Tua Community Secondary School, Korokoro; Community Secondary School, Koroma and Community Secondary School, Ban-Ogoi as Vice Principal (Administration). He finally Served as Director/ Principal at Community Comprehensive Secondary School, Nonwa/Gbam where he retired statutorily on 31 August 2021.

                                            During his Service Years, he was elected at different levels to represent the Teachers. He served as Secretary and Chairman of Nigeria Union of Teachers (NUT) in Tai Local Govt Area in 2001-2009 and 2009 - 2013 respectively. In June 2013 he was elected as Deputy Chairman of Nigeria Union of Teachers(NUT) Rivers State Wing. He became the Chairman of NUT in Rivers state on 13 August 2015 and won elections into the same office in June 2017 and July 2021.

                                            In 2021, he became the leader of the nine states of the Eastern Region ofAbia, Akwa/Ibom, Anambra, Bayelsa, Cross River, Ebonyi, Enugu, Imo and Rivers States.As Chairman of Nigeria Union of Teachers Rivers State Wing, he was a Member of Education International (ei) and also served as Member Zonal Coordination CommitteeOf West African Examination Council (WAEC).

                                            Comr. Sir D.L NKpogone attended Many Conferences, workshops and seminars at International, Regional and National levels.Some of the conferences and Workshops include; Education International (EI)in Bangkok, Thailand June 2019; International LaborOrganization (ILO) in Geneva, Switzerland in May /June 2018, Regional Conference on Education in Cote D'ivoirein November 2018. Others are, workshop on Entrepreneurship in University of Putra, Malaysia in January, 2020, Bilateral conference on Education in Singapore (Nigeria Union of Teachers and Singapore Teachers Union) in May 2018; Bilateral Workshop/ Conference in Zambia and Turkey in 2021. Some National Workshops and Conferences include; National Finance Workshop in Abuja in December 2019, and Safe School Conferences in Abuja.

                                            The Comrade Teacher also served in different Capacities as; President, Koroma Students Union 1983-1985, Secretary Tai/Eleme Farmers’ Co-operative Union Ltd, 1994 -1996; Member Tai/Eleme Local Government Area Environment Protection Committee, 1996. Secretary Koroma Community DevelopmentCommittee (CDC) 2008-2014 SecretayKoroma Tai Rural Development Association 1988-1990 and Member, Students Consultative Assembly Rivers State College of Education, Ndele Campus 1983-1984.

                                            Comr. Sir D. L Nipogone is a Member of Bodies like Institute of Chartered Secretaries and Administrators; Institute of Corporate Administration of Nigeria as (Distinguished Educationist) Knight of Service and Quality (KSQ) and an Ambassador of Peace (Word Peace Initiatives).
                                        <center><a  data-popup-close="popup-5" href="#"><b>CLOSE</b></a></center>
                                
                                    </p>
                                </div>
                                <a class="popup-close" data-popup-close="popup-5" href="#">x</a>
                            </div>
                        </div>
                    
                </div>
            </div>
            
        </div>
    </div>
    
</template>

<script>

import $ from 'jquery'
import jQuery from 'jquery'

$(function() {
    //----- OPEN
    $('[data-popup-open]').on('click', function(e)  {
        var targeted_popup_class = jQuery(this).attr('data-popup-open');
        $('[data-popup="' + targeted_popup_class + '"]').fadeIn(350);
 
        e.preventDefault();
    });
 
    //----- CLOSE
    $('[data-popup-close]').on('click', function(e)  {
        var targeted_popup_class = jQuery(this).attr('data-popup-close');
        $('[data-popup="' + targeted_popup_class + '"]').fadeOut(350);
 
        e.preventDefault();
    });
});

export default {
    name: 'Team'
}
</script>

