<template>
    <div class="app-wrap  ptb-100 bg-rock">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-8">
                    <div class="app-content sm-center">
                        <div class="content-title style2">
                            <h2>You can download our app here</h2>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-4 text-md-end sm-center">
                    <div class="app-btn">
                        <a target="_blank" href="https://play.google.com/store/apps"><img src="../../assets/images/play-store.png" alt="Image"></a>
                        <a target="_blank" href="https://www.apple.com/app-store/"><img src="../../assets/images/apple-store.png" alt="Image"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDownload'
}
</script>