<template>
    <div class="terms-wrap pt-100 pb-100">
        <div class="container">
            <div class="row gx-5">
                <div class="col-lg-12">
                    <div class="single-terms">
                        
                       
                    </div>
                    <div class="single-terms">
                        <h3>YOUR PRIVACY</h3>
                        <p>
                            <b>At NDDC Staff Coop Microfinance Bank</b> (NDDC Staff Coop Microfinance Bank ), we put you first and are thus committed to protecting and respecting your privacy. We are committed to being transparent about how we collect, process, share and manage data about you (our customers and other natural persons where applicable).

                        </p>
                        <p>
                            <b> NDDC STAFF COOP MICROFINANCE BANK AND YOU </b><br/>
                            This Privacy Policy stipulates the banks approach to handling your data and your rights with regards to our collection, use, storage and sharing of your personal data which may be collected by NDDC Staff Coop Microfinance Bank in the course of providing you with exceptional products and services, collectively referred to herein as NDDC Staff Coop Microfinance Bank “services”, to meet your needs.

                        </p>
                    </div>
                    <div class="single-terms">
                        <h3>YOUR PERSONAL INFORMATION</h3>
                        <p>In the course of consuming services provided to you by <b>NDDC Staff Coop Microfinance Bank </b> to meet your needs, through this and other channels available, we collect information you provide us via forms, phone calls, and correspondence by mail or emails, service point interfaces, and any other channels we may make available to you from time to time.</p>
                        
                        <h4>INFORMATION WE HOLD ABOUT YOU</h4>
                        <ul class="content-feature-list list-style mb-0">
                            <li><i class="ri-checkbox-multiple-line"></i><b>PERSONAL DATA TYPE</b>
                            <p>Description of Personal Data</p></li>
                            <li><i class="ri-checkbox-multiple-line"></i><b>IDENTITY DATA</b>
                             <p>Your personal details (for example, your name, date of birth, passport information or other identification information)</p></li>
                            <li><i class="ri-checkbox-multiple-line"></i><b>CONTACT DATA</b><p>Your contact details (for example, your postal address, phone number, email address or mobile number)</p></li>
                            
                            <li><i class="ri-checkbox-multiple-line"></i><b>FINANCIAL DATA</b>
                                <p>Data about your Financial profile</p></li>
                            <li><i class="ri-checkbox-multiple-line"></i><b>TRANSACTIONAL DATA</b>
                                <p>Details of transactions (for example, payments you make and receive)</p></li>
                                <li><i class="ri-checkbox-multiple-line"></i><b>TECHNICAL DATA</b>
                             <p>Internet protocol (IP) address, login data, details of browser and operating system, time zone setting and location, browser plug-in types and versions, platforms and other technology such as device id, geolocation, IP, model and user agent on the devices used to access the Bank’s website.</p></li>
                            <li><i class="ri-checkbox-multiple-line"></i><b>PROFILE DATA</b>
                                <p>Data that help us identify you</p></li>
                           <li><i class="ri-checkbox-multiple-line"></i><b>JOB APPLICATION DATA</b>
                           <p>Any personal information you provide to NDDC Staff Coop Microfinance Bank Plc as part of the recruitment process.</p>
                            </li>
                            
                            <li><i class="ri-checkbox-multiple-line"></i><b>USAGE DATA</b>
                           <p>Includes information about how data subject uses our website, products and services</p>
                            </li>
                            <li><i class="ri-checkbox-multiple-line"></i><b>MARKETING AND COMMUNICATION DATA</b>
                           <p>Information about data subject communications with the Bank. Preferences in receiving marketing e-mails and consents given by data subject to the Bank.</p>
                            </li>
                            <li><i class="ri-checkbox-multiple-line"></i><b>OTHERS</b>
                           <p>CCTV/Video footage whenever you come into our premises.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="single-terms">
                        <h3>WHY DO WE NEED THE DATA?</h3>
                        <p><b>NDDC Staff Coop Microfinance Bank</b>ensures that the personal data collected and processed is necessary for the purpose of collection, and NDDC Staff Coop Microfinance Bank shall not collect or process more data than is reasonably required for a particular processing activity. In addition, every processing purpose has at least one lawful basis for processing to safeguard the rights of the data subjects, as listed below:
Purpose of Processing.</p>
                    </div>
                    <div class="single-terms">
                        <h3>Lawful Basis of Processing</h3>
                        <p>
                          <b> Account Management:</b> Managing customer accounts, transactions, and providing banking services.
Performance of a contract: Processing personal data is necessary for the performance of a contract to which the data subject is a party (e.g., opening and maintaining a bank account).
Fcted by third parties and other service partners to better serve your needs. Third party sources are not controlled by NDDC Staff Coop Microfinance and as such we are not liable for how they use it.
                        </p>
                        <p>
                            <b>Fraud Prevention:</b> Detecting, preventing, and investigating fraudulent activities to protect customers and the bank.
                        </p>
                         <p>
                            <b>Customer Support:</b> Addressing inquiries, resolving complaints, and providing support services to customers
                        </p>
                         <p>
                            <b>Internal Reporting and Analysis:</b> Generating reports, conducting analysis, and making strategic decisions based on aggregated and anonymized data
                        </p>
                         <p>
                            <b>Business Development:</b> Identifying market trends, developing new products or services, and improving overall business operations.
                        </p>
                         <p>
                          <b>Compliance with Legal Obligations:</b> Fulfilling legal and regulatory requirements imposed on banks, such as anti-money laundering (AML) and know your customer (KYC) regulations.
                        </p>
                        <p>
                          <b>Legal Obligations:</b> Processing personal data is necessary for the legitimate interests pursued by the bank or a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject.
                        </p>
                        <p>
                          <b>Legitimate interests:</b> Processing personal data is necessary for the legitimate interests pursued by the bank or a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject.
                        </p>
                        <p>
                          <b>Marketing and Promotions:</b> Providing information about products, services, and promotional offers to customers, subject to their consent and preferences.
                        </p>
                         <p>
                          <b>Consent:</b>  Processing personal data is based on the data subject's consent, which must be freely given, specific, informed, and unambiguous. Data subjects have the right to withdraw consent at any time.
                        </p>
                    </div>
                    <div class="single-terms">
                        <h3>Consent</h3>
                        <p>We don't ask for your personal information unless we need it to provide or improve our products and services for you. We want to be sure we have your consent to collect, use and, where necessary, share your information with our partners and suppliers that help us serve you. Whenever we introduce new services and technologies, we'll ensure you understand and agree to any new ways in which your information is handled. You will be considered to have given your consent to NDDC Staff Coop Microfinance Bank and/or Subsidiaries for the processing of your personal data when:
You complete any form, brochure or material issued by NDDC Staff Coop Microfinance Bank at any of our service points (mobile, online etc.) requesting for such personal information.
You register, check or tick the acceptance box on any of our electronic platforms (Online or Mobile) relating to terms and conditions of any service or product offered by NDDC Staff Coop Microfinance Bank.
You send a request, complaint or other communication to NDDC Staff Coop Microfinance Bank 
You use any service or product offered by NDDC Staff Coop Microfinance Bank.</p>
                       
                    </div>
                    <div class="single-terms">
                        <h3>Use of Personal Information</h3>
                         <ol>
                            <li>To offer and provide our Products and Services tailored to meet your unique needs.</li>
                            <li>To fulfil the terms of any service contract(s) you might have with us.</li>
                            <li>To improve your service experience with us.</li>
                            <li>To conduct our business.</li>
                            <li>To develop statistics as may be required</li>
                            <li>To update your records.</li>
                            <li>To communicate with you when necessary.</li>
                            <li>To comply with our Internal Policies.</li>
                        </ol>
                    </div>

                    <div class="single-terms">
                        <h3>Information Protection</h3>
                         <p>NDDC Staff Coop Microfinance Bank will always ensure that your personal information is adequately protected. We have put in place processes and technologies to ensure that your personal information is not modified, lost, damaged or destroyed. Our people are trained to ensure that your personal information is not disclosed and safe as stated in this policy.
Where access and use of our electronic platforms requires authentication of the user, you shall be responsible for the use and safety of your authentication credential(s) including but not limited to Username, Personal Identification Number (PIN), Password, One Time Passwords (OTP) and Tokens.
NDDC Staff Coop Microfinance Bank will ensure that the personal data in our custody is secure and kept confidential as expected. We implement generally accepted technical and organizational measures to protect your personal data against unauthorized or unlawful processing, accidental loss, destruction, or damage, including encryption where appropriate.</p>
                    </div>

                    <div class="single-terms">
                        <h3>Information Sharing and Disclosure</h3>
                        <p><b>NDDC Staff Coop Microfinance Bank</b> will not sell or rent your personally identifiable information to anyone. NDDC Staff Coop Microfinance Bank may share or disclose your personal information to third parties where:</p>
                         <ul>
                            <li>We are required by law to share or disclose such personal information.</li>
                            <li>We have your consent to share or disclose such personal information.</li>
                            <li>We respond to subpoenas, court orders or other legal processes.</li>
                            <li>We find that your actions on our electronic platforms violate any of our Policies for the purpose of investigations, reporting and enforcing any of our rights.</li>
                            <li>Required for audit purposes.</li>
                            
                        </ul>
                        <p><b>NDDC Staff Coop Microfinance Bank</b> may use and share your personal information with its affiliates and for providing services and service-related activities such as collecting subscription fees for such services, notifying or contacting you regarding any problem with, or the expiration of such services. In this regard, the Affiliates  shall process the information as provided in this Privacy Policy.
                         <b>NDDC Staff Coop Microfinance Bank</b> may also disclose or share your personal information where it is necessary to enforce the terms and conditions of any of our Products and Services or any of our rights as well as to protect our operations and customers.</p>
                    </div>

                     <div class="single-terms">
                        <h3>Retention</h3>
                        <p>We will retain your personal information for such length of time as may be required by law, regulation, the internal policies of <b>NDDC Staff Coop Microfinance Bank</b>. This retention period has been established to enable us to use the personal data for the necessary legitimate purposes identified, in full compliance with the legal and regulatory requirements.</p>
                         
                       
                    </div>

                     <div class="single-terms">
                        <h3>Your Rights under this Policy</h3>
                        <p>According to the provision of Nigerian Data Protection Act 2023 (NDPA), data subjects have certain rights. The following rights are available to you under this Policy:</p>
                        <ul>
                     <li> You may at any time request for access to your personal information held by NDDC Staff Coop Microfinance Bank or request that your personal information be made available to a third party.</li>
                     <li> You may request to update your personal information with NDDC Staff Coop Microfinance Bank at any time.</li>
                       <li>You may elect to withdraw your consent at any time.</li>
                       <li>Your right to withdraw consent extends to objecting or restricting the processing of your personal data by NDDC Staff Coop Microfinance Bank You may request that your personal information be deleted. We may continue to retain such personal information as may be required for compliance with legal, regulatory or policy requirements.</li>
                       
                        </ul> 
                        
                    </div>

                    <div class="single-terms">
                        <h3>Remedies</h3>
                        <p>Where you have concerns relating to the processing of your personal information by NDDC Staff Coop Microfinance, or require any clarification on this policy, please notify us through or contact details provided below:</p>
                       <ul>
             <li>  Email: support@nsmbanklimited.com or enquiry@nsmbanklimited.com</li>
              <li> Phone:  (09036014622)</li>
              </ul>
                         
                        
                    </div>
                    

                <div class="single-terms">
                        <h3>Updates to this Privacy Policy</h3>
                        <p><b>NDDC Staff Coop Microfinance Bank</b> may update this policy from time to time. Where there are changes in the way we use your personal information we will notify you by posting a prominent notice on our website.</p>
                        
                        
                    </div>

                </div>

              
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>