<template>
    <div class="terms-wrap " style="padding-top: 38px">
    
        <div class="container">
            <div class="row" >
            
                <div class="col-xl-12 ">
                
                    <div class="single-terms">
                        <h3>History of the Bank </h3>
                        <p><b id="ourvision"/>
                            The NDDC Staff Micro Finance Bank Limited was promoted by the NDDC Port Harcourt Staff Co-operative Investment and Credit Society Limited. The idea behind formation of the Micro Finance Bank was born out of the determination of the Staff of the NDDC to help drive the economic prosperity of the oil rich Niger Delta Region which is a key component of the mandate of the NDDC. 
                            <br/>
                            <br/>
                            The establishment of the NDDC Staff Micro Finance Bank Limited started in 2019 by the 3rd Management Committee of the Cooperative led by Mr. Essien Franklin. After the expiration of their tenor in office, the 5th Management Committee led by EmerhanaOnoriode Paul, Esq, ACIArb. The Bank has its registered office at 129 Aba Road, Port Harcourt.
                            
            
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'TermsOfService'
}
</script>