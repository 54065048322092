<template>
    <section class="why-choose-wrap style1 pb-100 bg-bunting">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="wh-img-wrap">
                        <img src="../../assets/images/why-choose-us/wh-img-1.png" alt="Image">
                        <img class="wh-shape-one bounce" src="../../assets/images/why-choose-us/wh-shape-1.png" alt="Image">
                        <img class="wh-shape-two animationFramesTwo" src="../../assets/images/why-choose-us/wh-shape-2.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="wh-content">
                        <div class="content-title style1">
                            <span>Why Choose Us</span>
                            <h3>We offer you World Class &amp; Fastest Online financial Services</h3>
                            <p>We focus on taking away the financial hassles as much as possible; We ensure secured and easy financial footsteps and footprints for our valued customers. To enable us achieve this, we offer:</p>
                        </div>
                        <div class="feature-item-wrap">
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-graph"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Low Costing</h3>
                                    <p>We offer you competitive interest rates, that gives you the surety of saving more money on every transactions.</p>
                                </div>
                            </div>
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-loan-1"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Safe &amp; Secure</h3>
                                    <p>We provide you standard operating procedures to ensure secured and easy online and offline transactions.</p>
                                </div>
                            </div>
                            <div class="feature-item">
                                <span class="feature-icon">
                                    <i class="flaticon-computer"></i>
                                </span>
                                <div class="feature-text">
                                    <h3>Live Support</h3>
                                    <p>Our 24/7 customer helpdesk is available to resolve your request and queries to give you the best customer experience with us.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>