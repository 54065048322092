<template>
    <div class="service-wrap bg-albastor pt-30 pb-75">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                    <div class="section-title style1 text-center mb-40">
                        <span style="font-size:28px">Our Mission</span><br/>
                        <h7>At NSM Bank limited, we prioritize excellence; owing to this, we have a mission to be the preferred destination of choice in our chosen sphere by igniting customers’ positive experience through </h7>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                        <img src="../../assets/images/service/service-icon-1.png" alt="Image">
                        </span>
                        <h3><router-link to="/service-details">Courteous people</router-link></h3>
                        <p>To give you the best services, we strive to provide the most effective staff culture.</p>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                            <img src="../../assets/images/service/service-icon-2.png" alt="Image">
                        </span>
                        <h3><router-link to="/service-details">Quality products</router-link></h3>
                        <p>We are constantly reinventing ourselves to make our services most functional for you.</p>
                    </div>
                </div>
                
                <div class="col-xxl-3 col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style1">
                        <span class="service-icon">
                            <img src="../../assets/images/service/service-icon-3.png" alt="Image">
                        </span>
                        <h3><router-link to="/service-details">Innovation &amp; technology</router-link></h3>
                        <p>We have a goal of giving you financial utopia via the best innovations and technologies.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>