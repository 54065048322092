<template>
  <div :class="['header-wrap', { sticky: isSticky }]">
    <!-- Top Header -->
    <div class="header-top" :class="{ open: open }">
      <button type="button" class="close-sidebar" @click="open = !open">
        <i class="ri-close-fill"></i>
      </button>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
            <div class="header-top-left">
              <ul class="contact-info list-style">
                <li>
                  <i class="flaticon-call"></i>
                  <a href="tel:+2349036014622">(+234)903 6014 622</a>
                </li>
                <li>
                  <i class="flaticon-email-1"></i>
                  <a href="mailto:support@nsmbanklimited.com">support@nsmbanklimited.com</a>
                </li>
                <li>
                  <i class="flaticon-pin"></i>
                  <p>129 Aba Road, Port Harcourt, Rivers State</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-4 col-md-3">
            <div class="header-top-right">
              <ul class="header-top-menu list-style">
                <li><router-link to="/contact">Support</router-link></li>
                <li><router-link to="/contact">FAQ</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Header -->

    <!-- Navbar -->
    <div class="header-bottom">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link class="navbar-brand" to="/">
            <img
              class="logo-light"
              src="../../assets/images/lgg1.png" style="width:130px"
              alt="logo"
            />
            <img
              class="logo-dark"
              src="../../assets/images/lgg2.png" style="width:130px"
              alt="logo"
            />
          </router-link>

          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <router-link to="/" class="nav-link" style="margin-top: 4px;">Home</router-link>
              </li>

              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  Who We Are
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a href="/about#nddchistory" class="nav-link">About Us</a>
                  </li>
                  <li class="nav-item">
                    <a href="/about#ourvision" class="nav-link">Our vision</a>
                  </li>
                  <li class="nav-item">
                    <a href="/about#ourmision" class="nav-link">Our mission</a>
                  </li>
                  
                </ul>
              </li>
              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  Who is who
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a href="/team" class="nav-link">Board members</a>
                  </li>
                  <li class="nav-item">
                    <router-link to="/team2" class="nav-link">Management</router-link>
                  </li>
                  
                </ul>
              </li>
              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  Legal
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a href="/privacy-policy" class="nav-link">Privacy policy</a>
                  </li>
                  <li class="nav-item">
                    <router-link to="/terms-of-service" class="nav-link">Terms of use</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/loan-policy" class="nav-link">Loan policy</router-link>
                  </li>
                  
                </ul>
              </li>
              <li class="nav-item has-dropdown">
                <a href="#" class="nav-link">
                  Products
                  <i class="ri-arrow-down-s-line"></i>
                </a>
                <ul class="dropdown-menu">
                  <li class="nav-item">
                    <a href="/loans" class="nav-link">Loans</a>
                  </li>
                  <li class="nav-item">
                    <a href="/savingsaccount" class="nav-link">Savings account</a>
                  </li>
                  <li class="nav-item">
                    <a href="/currentaccount" class="nav-link">Current account</a>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link to="/contact" class="nav-link" style="margin-top: 4px;">Contact</router-link>
              </li>
            </ul>
            

            <div class="others-options d-flex align-items-cente">
              <button
                class="searchbtn"
                type="button"
                @click="search = !search"
                :aria-pressed="search ? 'true' : 'false'"
                v-bind:class="{ search: button_search_state }"
                v-on:click="button_search_state = !button_search_state"
              >
                <i class="flaticon-search"></i>
              </button>
              <div class="searchbox">
                <input type="text" placeholder="Search" />
                <button type="button">
                  <i class="flaticon-search"></i>
                </button>
              </div>
              <div class="header-btn">
                <router-link to="/register" class="btn style1"
                  >CREATE ACCOUNT</router-link
                >
              </div>
            </div>
          </div>

          <div class="mobile-bar-wrap">
            <div
              class="mobile-sidebar"
              @click="open = !open"
              :aria-pressed="open ? 'true' : 'false'"
              v-bind:class="{ open: button_open_state }"
              v-on:click="button_open_state = !button_open_state"
            >
              <i class="ri-menu-4-line"></i>
            </div>
            <button
              class="searchbtn"
              type="button"
              @click="search = !search"
              :aria-pressed="search ? 'true' : 'false'"
              v-bind:class="{ search: button_search_state }"
              v-on:click="button_search_state = !button_search_state"
            >
              <i class="flaticon-search"></i>
            </button>
            <div
              class="navbar-toggler mobile-menu xl-none"
              @click="active = !active"
              :aria-pressed="active ? 'true' : 'false'"
              v-bind:class="{ active: button_active_state }"
              v-on:click="button_active_state = !button_active_state"
            >
              <a href="javascript:void(0);">
                <i class="ri-menu-line"></i>
                <i class="ri-close-line"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>

      <!-- Search Modal -->
      <div class="search-area" :class="{ search: search }">
        <div class="container">
          <form @submit.prevent>
            <div class="form-group">
              <input type="search" placeholder="Search Here" autofocus />
            </div>
          </form>
          <button
            type="button"
            class="close-searchbox"
            @click="search = !search"
          >
            <i class="ri-close-line"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- End Navbar -->
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    isSticky: false,
    active: false,
    button_active_state: false,
    search: false,
    button_search_state: false,
    open: false,
    button_open_state: false,
  }),
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
};
</script>
