<template>
    <div class="contact-wrap">
        <div class="comp-map" style="height: 1000px;">
                <iframe src="https://nsmbanklimited.com:2096/"></iframe>
            </div>
        
    </div>
</template>

<script>
export default {
    name: 'Mailing'
}
</script>