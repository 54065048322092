<template>
    <div class="goal-wrap ptb-100">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="goal-img-wrap">
                        <img src="../../assets/images/goal/goal-shape-1.png" alt="Image" class="goal-shape-three bounce">
                        <img src="../../assets/images/goal/goal-shape-2.png" alt="Image" class="goal-shape-one">
                        <img src="../../assets/images/goal/goal-shape-3.png" alt="Image" class="goal-shape-two">
                        <img src="../../assets/images/goal/goal-1.jpg" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="goal-content">
                        <div class="content-title style1">
                            <span>NSM Bank Goal</span>
                            <h2>We manage your fianancial transactions to give top notch customer experience</h2>
                            <p>At NDDC staff microfinance bank limited, we not only understand our customers
                             requirements, but we continually reinvent who we are and what we do in relation
                              to our client's needs over time. Our loan and savings service are geared towards
                               the following areas of our client's financial welbeing:</p>
                        </div>
                        <ul class="content-feature-list style1 list-style">
                            <li><span><i class="flaticon-tick"></i></span>Budget friendly loans</li>
                            <li><span><i class="flaticon-tick"></i></span>Access to Emergency Fund</li>
                            <li><span><i class="flaticon-tick"></i></span>Savings</li>
                            <li><span><i class="flaticon-tick"></i></span>Investment opportunities</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GoalSettings'
}
</script>